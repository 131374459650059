import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import Back from '../components/back';

class StationaryIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulPost.edges');

    const allItems = {};
    posts.forEach((post) => {

        if (!allItems[post.node.itemType]) {
          allItems[post.node.itemType] = [];
        }
        allItems[post.node.itemType].push(post.node);
      
    });

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title="Dana Royal's Print Design" />
     
          <Back/>
          <div className="wrapper">
            {
              Object.keys(allItems).sort().map((key) => {
                return (
                  <ul className="article-list" key={key}>
                    {allItems[key].map((node) => {
                      return (
                        <li key={node.slug} style={{padding: '2rem'}}>
                          <ArticlePreview article={node} />
                        </li>
                      )
                    })}
                  </ul>
                )
              })
            }
            <section style={{clear: 'both', padding: '3.75rem', textAlign: 'center'}}>
              <a href="#">
                <span style={{display: 'inline-block', marginRight: '10px'}}>↑</span>
                <span>Back to Top</span>
              </a>
            </section>
          </div>
        </div>
      </Layout>
    )
  }
}

export default StationaryIndex

export const pageQuery = graphql`
  query StationaryQuery {
    allContentfulPost(filter: {itemType: {eq: "Wedding Invite"}}, sort: {fields: [priority]}) {
      edges {
        node {
          title
          slug
          itemType
          thumbnail {
            fixed(width: 350, height: 539, quality: 100) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  }
`
